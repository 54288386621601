import React, { useState } from "react";
import { navigate, PageProps } from "gatsby";
import { CognitoUser } from "amazon-cognito-identity-js";

import PasswordStrengthBar from "react-password-strength-bar";
import PasswordChecklist from "react-password-checklist";
import H1 from "../../components/htmlElements/h1";
import Layout from "../../components/layout";
import UserPool from "../../components/auth/UserPool";
import InputWrapper from "../../components/submitElements/inputWrapper";
import Button from "../../components/htmlElements/button";

const passwordRequirementsText =
  "Password must contain at least 1 uppercase, 1 lowercase, 1 special character and 1 number. The password must be minimal 8 characters long.";

export default function PasswordResetPage(PasswordResetPageProps: PageProps) {
  const [showPasswordForm, setShowPasswordForm] = useState(false);
  const [emailAddress, setEmailAddress] = useState("");
  const [verificationCode, setVerificationCode] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [newPassword2, setNewPassword2] = useState("");
  const [errorMessage, setErrorMessage] = useState("");

  const handleSubmit = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: emailAddress,
      Pool: UserPool,
    });

    user.forgotPassword({
      onSuccess(data) {
        // successfully initiated reset password request
        // console.log(`CodeDeliveryData from forgotPassword: ${data}`);
      },
      onFailure(err) {
        // console.log(err.message, err);

        // Do not show `Username/client id combination not found` message as this indicates the user does exist.
        // Just show the reset form which will do nothing.

        setErrorMessage("");
        setShowPasswordForm(true);
      },
      inputVerificationCode() {
        setErrorMessage("");
        setShowPasswordForm(true);
      },
    });
  };

  const passwordResetHandler = (e) => {
    e.preventDefault();

    const user = new CognitoUser({
      Username: emailAddress,
      Pool: UserPool,
    });

    if (newPassword === newPassword2) {
      user.confirmPassword(verificationCode, newPassword, {
        onSuccess(data) {
          navigate("/login");
        },
        onFailure(err) {
          setErrorMessage(err.message);
        },
      });
    } else {
      setErrorMessage("Passwords don't match.");
    }
  };

  const displayError = errorMessage ? (
    <div className="mt-4 border border-red/50 bg-red/10 p-1 pl-2 text-sm rounded">
      <p>{errorMessage}</p>
    </div>
  ) : (
    ""
  );

  const emailForm = (
    <>
      {displayError}
      <form className="mt-5">
        <InputWrapper title="Email address">
          <input
            type="text"
            name="email"
            className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
            placeholder="john@doe.com"
            required
            value={emailAddress}
            onChange={(e) => setEmailAddress(e.target.value)}
          />
        </InputWrapper>

        <Button innerContent="Send reset link" color="blue" type="submit" onClick={handleSubmit} />
      </form>
    </>
  );

  const passwordForm = (
    <>
      {displayError}
      <form className="mt-5">

        <p className="mb-2">Please check your email for the verification code we've sent you.</p>

        <InputWrapper title="Verification code">
          <input
            type="text"
            name="verification_code"
            className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
            required
            value={verificationCode}
            onChange={(e) => setVerificationCode(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper
          title="New password"
          infoBoxContent={passwordRequirementsText}
        >
          <input
            type="password"
            name="password"
            className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
            required
            value={newPassword}
            placeholder="*********"
            onChange={(e) => setNewPassword(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper title="Verify new password">
          <input
            type="password"
            name="password2"
            className="border border-gray-2 rounded px-2 py-2 font-sm   w-full  focus:outline-none focus:border-transparent"
            required
            value={newPassword2}
            placeholder="*********"
            onChange={(e) => setNewPassword2(e.target.value)}
          />
        </InputWrapper>

        <InputWrapper title="Password validation">
          <PasswordStrengthBar password={newPassword} minLength={5} />
          <PasswordChecklist
            rules={["minLength", "specialChar", "number", "capital", "match"]}
            minLength={8}
            value={newPassword}
            valueAgain={newPassword2}
          />
        </InputWrapper>

        <Button innerContent="Reset password" color="blue" type="submit" onClick={passwordResetHandler} />
      </form>
    </>
  );

  const displayForm = showPasswordForm ? passwordForm : emailForm;

  return (
    <Layout>
      <div className="grid grid-cols-12 gap-4">
        <div className="col-span-12 md:col-span-7">
          <H1 innerContent="Reset your password" additionalClasses="pb-5" />
          {displayForm}
        </div>
      </div>
    </Layout>
  );
}
